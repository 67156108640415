import { useState, useEffect, useCallback } from "react";
import React from "react";
import axios from "axios";

import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import MedidorNtc from "./MedidorNtc";
import Instructivos from "../Administracion/Instructivos";
function NTC() {
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const maxValLength = 500;
  const handleOnChange = useCallback((e) => {
    const value = e.target.value;
    setValue(value);
    if (value === "error") setError("This is an example of an error message");
    else if (value.length > 500)
      setError(
        `max character count reached, please shorten your message by atleast ${
          value.length - maxValLength
        } characters`
      );
    else setError("");
  }, []);

  let params = useParams();
  let { id } = useParams();
  const [postCumple, setPostCumple] = useState([]);
  const [postPorcentaje, setPostPorcentaje] = useState([]);
  const [postFalta, setPostFalta] = useState([]);
  const [list1, setList1] = useState([]);

  useEffect(() => {
    axios({
      method: "get",
      url:
        "https://autodiagnosticoback.azurewebsites.net/api/ObtenerPreguntasPorIdEvaluacion/" +
        id,
    })
      .then((Response) => {
        setList1(Response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList1]);

  const postData = (e) => {
    axios({
      method: "post",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      url: "https://autodiagnosticoback.azurewebsites.net/api/PresentaEvaluacion",
      data: {
        idEvaluacion: params.id,
        Seccion: Seccion,
        Pregunta: Pregunta,
        cumple: postCumple + "",
        porcentaje: postPorcentaje,
        falta: postFalta + "",
        idPregunta: Id,
      },
    })
      .then((Response) => {
        console.log(Response.data);
        if (Response.data == id) {
          window.location.href = "/#/Resultados";
          window.location.reload("/");
        } else {
          Swal.fire({
            title: Response.data,
            text: "Una vez continue no puede regresar",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              console.log(Response.data);

              window.location.reload("/");
            } else {
              Swal.fire("Your imaginary file is safe!");
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error!",
          text: "verifique los campos",
          icon: "error",
          timer: 4000,
        });
      });
  };

  const stringNorma = list1.map((item) => {
    return item.normaId;
  });
  const Norma = stringNorma.join(
    list1.map((item) => {
      return item.normaId;
    })
  );

  const stringSeccion = list1.map((item) => {
    return item.seccion;
  });

  const Seccion = stringSeccion.join(
    list1.map((item) => {
      return item.seccion;
    })
  );

  const stringPregunta = list1.map((item) => {
    return item.pregunta;
  });
  const Pregunta = stringPregunta.join(
    list1.map((item) => {
      return item.pregunta;
    })
  );

  const stringId = list1.map((item) => {
    return item.id;
  });
  const Id = stringId.join(
    list1.map((item) => {
      return item.id;
    })
  );

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="text-justify">
          <div class="container-fluid">
            <div class="card shadow mb-4">
              <div class="card-body">
                <center>
                  {list1.map((item) => {
                    return (
                      <tr>
                        <div>{<Instructivos NormaId={item.normaId} />} </div>
                        {
                          <center>
                            <h1 class="m-0 font-weight-bold text-primary">
                              Sección {Seccion}
                            </h1>
                          </center>
                        }
                        <hr />
                        <h3> Pregunta:</h3>
                      </tr>
                    );
                  })}
                </center>
                {list1.map((item) => {
                  return (
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="6"
                      placeholder={item.pregunta}
                      disabled
                    />
                  );
                })}
              </div>
            </div>
            <div class="table-responsive">
              <table
                class="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <tr>
                  <td>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="4"
                      maxLength={500}
                      placeholder="Como lo estamos cumpliendo"
                      onChange={(e) => setPostCumple(e.target.value)}
                    ></textarea>
                  </td>
                  <td>
                    <select
                      className="btn btn-secondary btn-user btn-block"
                      onChange={(e) => setPostPorcentaje(e.target.value)}
                    >
                      <option value="">Seleccione</option>
                      <option value="0">0%</option>
                      <option value="1">10%</option>
                      <option value="2">20%</option>
                      <option value="3">30%</option>
                      <option value="4">40%</option>
                      <option value="5">50%</option>
                      <option value="6">60%</option>
                      <option value="7">70%</option>
                      <option value="8">80%</option>
                      <option value="9">90%</option>
                      <option value="10">100%</option>
                    </select>
                  </td>
                  <td>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="4"
                      maxLength={500}
                      placeholder="¿Qué nos hace falta?"
                      onChange={(e) => setPostFalta(e.target.value)}
                    ></textarea>
                  </td>
                </tr>
              </table>
              <a>
                <button
                  className="btn btn-primary btn-user btn-block"
                  onClick={postData}
                >
                  Guardar
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default NTC;
