import React from 'react'
import { useState } from 'react';
import ReactModal from 'react-modal'

function MedidorNtc() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const handleOpenModalTest = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <div>
   
        <div>
          <hr />
          <h3 class="m-0 font-weight-bold text-secondary">Criterios de evaluación</h3>
          


        
          <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0" >
            <td colSpan={2} bgcolor="#CBCABB"><b>ESCALA</b></td>
            <tr>
              <td rowSpan={4}>Se cuenta con un SGI maduro</td>

            </tr>
            <tr>
              <td bgcolor="#0EB81B">100%</td>
            </tr>
            <tr>
              <td bgcolor="#0FDE1F">90%</td>
            </tr>
            <tr>
              <td bgcolor="#80DE0F">80%</td>
            </tr>


            <tr>
              <td rowSpan={4}>EL SGI en estado avanzado de desarrollo, pero no maduro</td>

            </tr>
            <tr>
              <td bgcolor="#BFDE0F">70%</td>
            </tr>
            <tr>
              <td bgcolor="#E9E610">60%</td>
            </tr>
            <tr>
              <td bgcolor="#FFFC09">50%</td>
            </tr>
            <tr>
              <td rowSpan={4}>El SGI se encuentra en estado de implementación</td>

            </tr>
            <tr>
              <td bgcolor="#FFC203">40%</td>
            </tr>
            <tr>
              <td bgcolor="#FF9C03">30%</td>
            </tr>
            <tr>
              <td bgcolor="#FF7203">20%</td>
            </tr>
            <tr>
              <td rowSpan={3}>El SGI se encuentra en un estado inicial de definición</td>

            </tr>
            <tr>
              <td bgcolor="#FF3C03">10%</td>
            </tr>
            <tr>
              <td bgcolor="#FF0000">0%</td>
            </tr>


          </table>
      

        </div>
    

    </div>
  )
}

export default MedidorNtc