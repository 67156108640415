import axios from "axios";
import React from "react";
import { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import CreaPreguntas from "./CreaPreguntas";
import EditarPreguntas from "./EditaPreguntas";
import EliminarPreguntas from "./EliminaPreguntas";

function InfoPreguntas() {
  const [list, setList] = useState([]);
  useEffect(() => {
    axios({
      auth: { username: "webapp", password: "762tsfsy9826kk87ytttsf" },
      method: "get",
      url: "https://autodiagnosticoback.azurewebsites.net/api/ObtenerPreguntas",
    })
      .then((Response) => {
        setList(Response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList]);
  return (
    <div>
      <Fragment>
        <div className="container">
          <div className="text-center">
            <div class="container-fluid">
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">Preguntas</h6>
                </div>
                <div class="card-body">
                  <CreaPreguntas />
                  <br></br>
                  <div class="table-responsive">
                    <table
                      class="table table-bordered"
                      id="dataTable"
                      width="100%"
                      cellspacing="0"
                    >
                      <thead>
                        <tr>
                          <th>Seccion</th>
                          <th>Pregunta</th>
                          <th>NormaId</th>
                          <th>Consecutivo</th>
                          <th>Editar</th>
                          <th>Eliminar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((item) => {
                          return (
                            <tr>
                              <td>{item.seccion}</td>
                              <td>{item.pregunta}</td>
                              <td>{item.normaId}</td>
                              <td>{item.consecutivo}</td>
                              <th>
                                <EditarPreguntas
                                  id={item.id}
                                  descripcion={item.pregunta}
                                />
                              </th>
                              <td>
                                <EliminarPreguntas id={item.id} />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </div>
  );
}

export default InfoPreguntas;
