import axios from "axios";

import React from "react";
import { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import CreaPreguntas from "./CreaPreguntas";
import EditarPreguntas from "./EditaPreguntas";
import EliminarPreguntas from "./EliminaPreguntas";
import ReactModal from "react-modal";

function InfoPreguntasxSeccion(props) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const handleOpenModalTest = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const searcher = (e) => {
    setSearch(e.target.value);
  };
  const results = !search
    ? users
    : users.filter((dato) =>
        dato.pregunta.toLowerCase().includes(search.toLocaleLowerCase())
      );
  useEffect(() => {
    showData();
  }, []);

  const URL =
    "https://autodiagnosticoback.azurewebsites.net/api/ObtenerPreguntaPorSeccion/" +
    props.idSeccion +
    "/" +
    props.idNorma;
  const showData = async () => {
    const response = await fetch(URL);
    const data = await response.json();
    //console.log(data)
    setUsers(data);
  };
  return (
    <div>
      <Fragment>
        <button
          variant="contained"
          className="btn btn-primary "
          color="primary"
          onClick={handleOpenModalTest}
        >
          Preguntas
        </button>
        <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Crear Institucion"
        >
          <div className="text-justify">
            <div class="container-fluid">
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">Preguntas</h6>
                </div>
                <div class="card-body">
                  <CreaPreguntas
                    seccionId={props.idSeccion}
                    normaId={props.idNorma}
                  />
                  <br></br>
                  <input
                    value={search}
                    onChange={searcher}
                    type="text"
                    placeholder="Buscar por pregunta "
                    className="form-control"
                  />
                  <div class="table-responsive">
                    <table
                      class="table table-bordered"
                      id="dataTable"
                      width="100%"
                      cellspacing="0"
                    >
                      <thead>
                        <tr>
                          <th>Seccion</th>
                          <th>Pregunta</th>

                          <th>Editar</th>
                          <th>Eliminar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {results.map((item) => {
                          return (
                            <tr>
                              <td>{item.seccion}</td>
                              <td>{item.pregunta}</td>

                              <th>
                                <EditarPreguntas
                                  id={item.id}
                                  seccionId={props.idSeccion}
                                  pregunta={item.pregunta}
                                />
                              </th>
                              <td>
                                <EliminarPreguntas id={item.id} />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <button className="btn btn-secondary" onClick={closeModal}>
                      Volver
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
      </Fragment>
    </div>
  );
}

export default InfoPreguntasxSeccion;
