import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Chart from "react-apexcharts";
import ReactModal from "react-modal";
import MedidorNtc from "../Formato1/MedidorNtc";
import * as IoIcons from "react-icons/io";
function Barchart(props) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const handleOpenModalTest = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const [list, setList] = useState([]);

  useEffect(() => {
    axios({
      method: "get",
      url:
        "https://autodiagnosticoback.azurewebsites.net/api/ObtenerReporteSecciones/" +
        props.idNorma,
    })
      .then((Response) => {
        setList(Response.data);
        console.log(Response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList]);

  const constpalabra = props.nombreNorma;
  const palabra = constpalabra.replace("AUTOEVALUACIÓN", "");

  console.log(palabra);
  return (
    <React.Fragment>
      <button
        variant="contained"
        className="btn btn-secondary "
        color="primary"
        onClick={handleOpenModalTest}
      >
        {" "}
        Consultar
      </button>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Crear Institucion"
      >
        <br></br> <br></br>
        <center>
          <h3 class="m-0 font-weight-bold text-primary">{props.nombreNorma}</h3>
        </center>
        <left>
          <button className="btn btn-secondary" onClick={closeModal}>
            <IoIcons.IoIosCloseCircle />
          </button>
        </left>
        <table width={"100%"}>
          <td>
            <div className="container-fluid mb-5">
              {props.ponderado >= 8 ? (
                <td bgcolor="#BFDE0F">
                  <h3 className="text-center mt-3 mb-3 text-light">
                    NIVEL DE MADUREZ {props.ponderado}0%
                  </h3>
                </td>
              ) : props.ponderado <= 7 && props.ponderado >= 5 ? (
                <td bgcolor="#fbff03">
                  <h3 className="text-center mt-3 mb-3 ">
                    NIVEL DE MADUREZ {props.ponderado}0%
                  </h3>
                </td>
              ) : props.ponderado <= 4 ? (
                <td bgcolor="#FF7203">
                  <h3 className="text-center mt-3 mb-3 text-light">
                    NIVEL DE MADUREZ {props.ponderado}0%
                  </h3>
                </td>
              ) : (
                <hq></hq>
              )}
              {props.ponderado == 0 || props.ponderado == 1 ? (
                <div>
                  {" "}
                  <h3>
                    {" "}
                    El {palabra} se encuentra en un estado inicial de definición
                  </h3>
                </div>
              ) : props.ponderado == 2 ||
                props.ponderado == 3 ||
                props.ponderado == 4 ? (
                <div>
                  {" "}
                  <h3>El {palabra} se encuentra en estado de implementación</h3>
                </div>
              ) : props.ponderado == 5 ||
                props.ponderado == 6 ||
                props.ponderado == 7 ? (
                <div>
                  {" "}
                  <h3>
                    EL {palabra} en estado avanzado de desarrollo, pero no
                    maduro{" "}
                  </h3>
                </div>
              ) : props.ponderado == 8 ||
                props.ponderado == 9 ||
                props.ponderado == 10 ? (
                <div>
                  {" "}
                  <h3> Se cuenta con un {palabra} maduro</h3>
                </div>
              ) : (
                <h></h>
              )}

              {props.ponderado == 0 ||
              props.ponderado == 1 ||
              props.ponderado == 2 ||
              props.ponderado == 3 ||
              props.ponderado == 5 ? (
                <div>
                  {" "}
                  <h5>
                    {" "}
                    (Solicite acompañamiento tecnico y/o cursos de capacitación,
                    se requieren recursos importantes y un nivel de esfuerzo
                    alto para cerrar brechas de cumplimiento)
                  </h5>
                </div>
              ) : props.ponderado == 9 || props.ponderado == 10 ? (
                <h5>
                  {" "}
                  ( Esta en disposicion de solicitar su auditoría de
                  certificación cierre las brechas detectadas con anterioridad
                  es muy factible que logre la certificación)
                </h5>
              ) : (
                <h5>
                  (Tome cursos de capacitacion para reforzar y complementar su
                  conocimiento en la norma. se requiere un nievel de recursos y
                  esfuerzo intermedio para lograr el cierre de las brechas
                  detectadas)
                </h5>
              )}
              <ul>
                <i>
                  <strong>
                    para más detalle pase el cursor por la barra de la sección
                  </strong>
                </i>
              </ul>
              <center>
                <Chart
                  type="bar"
                  width="1200"
                  height="420"
                  series={[
                    {
                      name: "Puntaje final",
                      data: list.map((x) => x.ponderado + "0"),
                      color: "#0085CA",
                    },
                  ]}
                  options={{
                    xaxis: {
                      tickPlacement: "on",
                      categories: list.map((x) => x.nombreSeccion),
                      show: false,
                      title: {
                        text: "Porcentaje x Sección",
                        style: { color: "#0085CA", fontSize: 30 },
                      },
                    },

                    yaxis: {
                      labels: {
                        formatter: (val) => {
                          return `${val}`;
                        },
                        style: { fontSize: "15", colors: ["#0085CA"] },
                      },
                    },

                    legend: {
                      show: true,
                      position: "right",
                    },

                    dataLabels: {
                      formatter: (val) => {
                        return `${val}`;
                      },
                      style: {
                        colors: ["#f4f4f4"],
                        fontSize: 15,
                      },
                    },
                  }}
                ></Chart>
              </center>
            </div>
          </td>
        </table>
        <MedidorNtc />
      </ReactModal>
    </React.Fragment>
  );
}

export default Barchart;
