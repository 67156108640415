import React from 'react'
import Continuar from './Formato1/Continuar'
import InfoResultados from './Resultados/InfoResultados'

function InfoNormasResultados() {
  return (
    <div>
        <Continuar/>
        <InfoResultados/>
        

    </div>
  )
}

export default InfoNormasResultados