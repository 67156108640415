import React from 'react'
import fondo from '../imagenes/fondo.svg';
function Fondo() {
  return (
    <div
    class="bg_image"
    style={{
      backgroundImage: 'url('+fondo+')',
      backgroundSize: "cover",
      height: "120vh",
      color: "#f5f5f5"
    }}
  >
   
  </div>
);
}

export default Fondo