import logo from "./logo.svg";
import "./App.css";

import React from "react";
import { HashRouter as Router, Route, Link, Routes } from "react-router-dom";
import NTC from "./components/Formato1/NTC";

import sbadmin2 from "../src/css/sb-admin-2.css";

import Login from "./components/Login";
import Home from "./components/Home";

import Sidebar from "./components/Sidebar";
import Cookies from "universal-cookie";
import Fondo from "./components/fondo";
import Footer from "./components/Footer";
import InfoNormas from "./components/Administracion/InfoNormas";
import InfoPreguntas from "./components/Administracion/InfoPreguntas";
import InfoSecciones from "./components/Administracion/InfoSecciones";
import InfoResultados from "./components/Resultados/InfoResultados";
import ConsultarResultados from "./components/Resultados/ConsultarResultados";
import InfoNormasResultados from "./components/InfoNormasResultados";

function App() {
  const cookie = new Cookies();
  return (
    <React.Fragment>
      <Router>
        {cookie.get("nitNumber") ? <Sidebar /> : <h1></h1>}
        <Routes>
          {cookie.get("nitNumber") ? (
            <Route exact path="/" element={<Home />} />
          ) : (
            <Route exact path="/" element={<Login />} />
          )}
        </Routes>
        <br></br> <br></br> <br></br> <br></br>
        <Routes>
          <Route exact path="/Home" element={<Home />} />
        </Routes>
        <Routes>
          <Route exact path="/fondo" element={<Fondo />} />
        </Routes>
        <Routes>
          <Route exact path="/InfoNormas" element={<InfoNormas />} />
        </Routes>
        <Routes>
          <Route exact path="/InfoPreguntas" element={<InfoPreguntas />} />
        </Routes>
        <Routes>
          <Route exact path="/InfoSecciones" element={<InfoSecciones />} />
        </Routes>
        <Routes>
          <Route exact path="/NTC/:id" element={<NTC />} />
        </Routes>
        <Routes>
          <Route exact path="/Resultados" element={<InfoNormasResultados />} />
        </Routes>
        <Routes>
          <Route exact path="/Consultar" element={<ConsultarResultados />} />
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
