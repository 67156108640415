import axios from "axios";

import React from "react";
import { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import CreaNorma from "./CreaNorma";
import DetalleNorma from "./DetalleNorma";
import EliminarNorma from "./EliminarNorma";
import InfoSecciones from "./InfoSecciones";

function InfoNormas() {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const searcher = (e) => {
    setSearch(e.target.value);
  };
  const results = !search
    ? users
    : users.filter((dato) =>
        dato.norma.toLowerCase().includes(search.toLocaleLowerCase())
      );
  useEffect(() => {
    showData();
  }, []);

  const URL =
    "https://autodiagnosticoback.azurewebsites.net/api/ObtenerTodasNormas";
  const showData = async () => {
    const response = await fetch(URL);
    const data = await response.json();
    //console.log(data)
    setUsers(data);
  };
  return (
    <div>
      <Fragment>
        <div className="container">
          <div className="text-center">
            <div class="container-fluid">
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">Normas</h6>
                </div>
                <div class="card-body">
                  <CreaNorma />
                  <br></br>
                  <input
                    value={search}
                    onChange={searcher}
                    type="text"
                    placeholder="Buscar por norma "
                    className="form-control"
                  />
                  <div class="table-responsive">
                    <table
                      class="table table-bordered"
                      id="dataTable"
                      width="100%"
                      cellspacing="0"
                    >
                      <thead>
                        <tr>
                          <th>Norma</th>
                          <th>Detalle</th>
                          <th>Secciones</th>
                          <th>Eliminar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {results.map((item) => {
                          return (
                            <tr>
                              <td>{item.norma}</td>
                              <td>
                                <DetalleNorma
                                  Objetivo={item.objetivo}
                                  Metodologia={item.metodologia}
                                  Instrucciones={item.instrucciones}
                                />
                              </td>
                              <td>
                                <InfoSecciones id={item.id} />
                              </td>
                              <td>
                                <EliminarNorma id={item.id} />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </div>
  );
}

export default InfoNormas;
