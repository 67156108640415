import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import CerrarSesion from './CerrarSesion';
import Cookies from 'universal-cookie';
import { isDisabled } from '@testing-library/user-event/dist/utils';
const cookie = new Cookies();
export const SidebarDataCliente = [
  {
    
    title: cookie.get("cliente"),
    path: isDisabled,
    icon: <IoIcons.IoIosPerson />,
    

  },
  {
    title: "Nueva Autoevaluación",
    path: '/Home',
    icon: <IoIcons.IoIosPaper />,
  },
  {
    title: "Ver Resultados",
    path: '/Resultados',
    icon: <IoIcons.IoIosAlbums />,
  },

  
  {
    title: <CerrarSesion/>,
    path: '/'
  },
    
  

 

];

