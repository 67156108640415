import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

function Instructivos(props) {
  const [list1, setList1] = useState([]);
  useEffect(() => {
    axios({
      method: "get",
      url:
        "https://autodiagnosticoback.azurewebsites.net/api/ObtenerNormas/" +
        props.NormaId,
    })
      .then((Response) => {
        setList1(Response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList1]);
  const [mostrarComponente, setMostrarComponente] = useState(false);

  return (
    <div>
      <div class="card-header py-6">
        <h6 class="m-0 font-weight-bold text-primary">
          {" "}
          {list1.map((item) => {
            return item.norma;
          })}
        </h6>
        <br></br>
      </div>
      <div class="card-body">
        <button
          variant="contained"
          className="btn btn-primary"
          onClick={() => setMostrarComponente(!mostrarComponente)}
        >
          {/*Aqui solo cambio el texto de mi boton, para el ejemplo */}
          {mostrarComponente
            ? `Ocultar`
            : `Mostrar Información relevante de la Norma`}
        </button>
        <div className={mostrarComponente ? "show-element" : null}>
          {mostrarComponente && (
            <div class="table-responsive">
              <table
                class="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>Objetivos</th>
                  </tr>
                </thead>
                <tbody>
                  {list1.map((item) => {
                    return (
                      <tr>
                        <td>{item.objetivo}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <thead>
                  <tr>
                    <th>Metodología</th>
                  </tr>
                </thead>

                <tbody>
                  {list1.map((item) => {
                    return (
                      <tr>
                        <td>{item.metodologia}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <thead>
                  <tr>
                    <th>Fuentes de información </th>
                  </tr>
                </thead>
                <tbody>
                  {list1.map((item) => {
                    return (
                      <tr>
                        <td>{item.instrucciones}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Instructivos;
