import React, { Fragment, useEffect, useState } from "react";
import ReactModal from "react-modal";
import axios from "axios";
//impoertacion componentes

function DetalleNorma(props) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const handleOpenModalTest = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="test">
      <button
        variant="contained"
        className="btn btn-primary "
        color="primary"
        onClick={handleOpenModalTest}
      >
        Detalle Norma
      </button>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Crear Institucion"
      >
        <div className="container">
          <div className="text-center">
            <div class="container-fluid">
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">
                    Instrucciones
                  </h6>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <form className="user">
                      <div className="form-group row">
                        <div className="col-sm-12">
                          OBJETIVO:
                          <textarea
                            type="text"
                            className="form-control form-control-user"
                            placeholder={props.Objetivo}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-12">
                          METODOLOGIA:
                          <textarea
                            type="text"
                            className="form-control form-control-user"
                            placeholder={props.Metodologia}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-12">
                          FUENTES DE INFORMACIÓN:
                          <textarea
                            type="text"
                            className="form-control form-control-user"
                            placeholder={props.Instrucciones}
                            disabled
                          />
                        </div>
                      </div>

                      <hr />
                    </form>
                    <button className="btn btn-secondary" onClick={closeModal}>
                      Volver
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}
export default DetalleNorma;
