import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import ReactModal from "react-modal";
import * as IoIcons from "react-icons/io";

function Detalles(props) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const handleOpenModalTest = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const searcher = (e) => {
    setSearch(e.target.value);
  };
  const results = !search
    ? users
    : users.filter((dato) =>
        dato.pregunta.toLowerCase().includes(search.toLocaleLowerCase())
      );
  useEffect(() => {
    showData();
  }, []);

  const URL =
    "https://autodiagnosticoback.azurewebsites.net/api/ObtenerDetallesEvaluacion/" +
    props.idEvaluacion;
  const showData = async () => {
    const response = await fetch(URL);
    const data = await response.json();
    //console.log(data)
    setUsers(data);
  };
  return (
    <div>
      <div className="container">
        <button
          variant="contained"
          className="btn btn-secondary "
          color="primary"
          onClick={handleOpenModalTest}
        >
          {" "}
          Detalles{" "}
        </button>
        <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Crear Institucion"
        >
          <br></br> <br></br>
          <button className="btn btn-secondary" onClick={closeModal}>
            <IoIcons.IoIosCloseCircle />
          </button>
          <div className="text-justify">
            <center>
              <h3 class="m-0 font-weight-bold text-primary">
                {props.nombreNorma}
              </h3>
            </center>
            <div class="container-fluid">
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">
                    Evaluaciones Presentadas{" "}
                  </h6>
                </div>

                <div class="card-body">
                  <ReactHtmlTableToExcel
                    id="boton excel"
                    className="btn btn-success"
                    table="tableDetalles"
                    filename="Resultados"
                    sheet="prueba"
                    buttonText="Exportar a Excel"
                  />
                  <br></br>

                  <div class="table-responsive">
                    <input
                      value={search}
                      onChange={searcher}
                      type="text"
                      placeholder="Buscar por pregunta "
                      className="form-control"
                    />

                    <table
                      class="table table-bordered"
                      id="tableDetalles"
                      width="100%"
                      cellspacing="0"
                    >
                      <thead>
                        <tr>
                          <th>
                            <h6 class="m-0 font-weight-bold text-primary">
                              Sección{" "}
                            </h6>
                          </th>
                          <th>
                            <h6 class="m-0 font-weight-bold text-primary">
                              Pregunta{" "}
                            </h6>
                          </th>
                          <th>
                            <h6 class="m-0 font-weight-bold text-primary">
                              Como lo estamos cumpliendo?
                            </h6>
                          </th>
                          <th>
                            <h6 class="m-0 font-weight-bold text-primary">
                              {" "}
                              % Cumplimiento{" "}
                            </h6>
                          </th>
                          <th>
                            <h6 class="m-0 font-weight-bold text-primary">
                              ¿Qué nos hace falta?{" "}
                            </h6>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {results.map((item) => {
                          return (
                            <tr>
                              <td>{item.descripcion}</td>
                              <td>{item.pregunta}</td>
                              <td>{item.cumple}</td>
                              <td>{item.porcentaje}0%</td>
                              <td>{item.falta}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    </div>
  );
}

export default Detalles;
