import React, { Fragment, useEffect, useState } from "react";
import ReactModal from "react-modal";
import axios from "axios";
//impoertacion componentes
import * as IoIcons from "react-icons/io";
import Swal from "sweetalert2";

function EditarPreguntas(props) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const handleOpenModalTest = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const [postPregunta, setPostPregunta] = useState([]);

  const postData = (e) => {
    e.preventDefault(e);
    if (postPregunta != "") {
      axios({
        auth: { username: "webapp", password: "762tsfsy9826kk87ytttsf" },
        method: "put",
        url:
          "https://autodiagnosticoback.azurewebsites.net/api/ActualizaPregunta/" +
          props.id,
        data: {
          pregunta: postPregunta,
          seccion: props.seccionId,
        },
      })
        .then((Response) => {
          Swal.fire({
            title: Response.data,
            icon: "success",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              console.log(Response.data);

              window.location.reload("/");
            } else {
              Swal.fire("Your imaginary file is safe!");
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error en los campos",
            text: "Intente de nuevo.",
            icon: "warning",
            timer: 3500,
          });
        });
    }
  };
  return (
    <div className="test">
      <button
        variant="contained"
        className="btn btn-warning"
        onClick={handleOpenModalTest}
      >
        {" "}
        <IoIcons.IoIosSearch />
      </button>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Crear Institucion"
      >
        <div className="container">
          <div className="text-center">
            <div class="container-fluid">
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">
                    Actualiza Preguntas
                  </h6>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <form className="user">
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <textarea
                            type="text"
                            rows={4}
                            className="form-control form-control-user"
                            onChange={(e) => setPostPregunta(e.target.value)}
                          >
                            {props.pregunta}
                          </textarea>
                        </div>
                      </div>

                      <a>
                        <button
                          className="btn btn-primary btn-user btn-block"
                          onClick={postData}
                        >
                          Guardar
                        </button>
                      </a>
                      <hr />
                    </form>
                    <button className="btn btn-secondary" onClick={closeModal}>
                      Volver
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}
export default EditarPreguntas;
