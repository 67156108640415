import React, { useState } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import Swal from "sweetalert2";

function CreaPreguntas(props) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const handleOpenModalTest = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const [postSeccion, setPostSeccion] = useState([]);
  const [postPregunta, setPostPregunta] = useState([]);
  const [postNormaId, setPostNormaId] = useState([]);
  const [postConsecutivo, setPostConsecutivo] = useState([]);

  const postData = (e) => {
    e.preventDefault(e);
    axios({
      auth: { username: "webapp", password: "762tsfsy9826kk87ytttsf" },
      method: "post",
      url: "https://autodiagnosticoback.azurewebsites.net/api/CreaPreguntas",
      data: {
        seccion: props.seccionId,
        pregunta: postPregunta,
        normaId: props.normaId,
        consecutivo: postConsecutivo,
      },
    })
      .then((Response) => {
        Swal.fire({
          title: Response.data,
          icon: "success",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            console.log(Response.data);

            window.location.reload("/");
          } else {
            Swal.fire("Your imaginary file is safe!");
          }
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Verifique los campos",
          icon: "error",
          buttons: true,
          dangerMode: true,
        });
      });
  };
  return (
    <div className="test">
      <button
        variant="contained"
        className="btn btn-primary "
        color="primary"
        onClick={handleOpenModalTest}
      >
        {" "}
        Nueva Pregunta
      </button>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Crear Institucion"
      >
        <div className="container">
          <div className="text-center">
            <div class="container-fluid">
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">
                    Crear Preguntas
                  </h6>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <form className="user">
                      <div className="form-group row">
                        <div className="col-sm-3">
                          <input
                            type="number"
                            className="form-control form-control-user"
                            placeholder="Consecutivo"
                            onChange={(e) => setPostConsecutivo(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <textarea
                            type="text"
                            className="form-control form-control-user"
                            placeholder="Pregunta"
                            maxLength={500}
                            onChange={(e) => setPostPregunta(e.target.value)}
                          />
                        </div>
                      </div>

                      <a>
                        <button
                          className="btn btn-primary btn-user btn-block"
                          onClick={postData}
                        >
                          Guardar
                        </button>
                      </a>
                      <hr />
                    </form>
                    <button className="btn btn-secondary" onClick={closeModal}>
                      Volver
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}
export default CreaPreguntas;
