import React from "react";
import Cookies from "universal-cookie";

function CerrarSesion() {
  const cookie = new Cookies();

  const cerrarSecion = () => {
    cookie.remove("cliente", { path: "/" });
    cookie.remove("nitNumber", { path: "/" });
    cookie.remove("nit", { path: "/" });
    cookie.remove("afiliado", { path: "/" });
    cookie.remove("numeroCuenta", { path: "/" });
    cookie.remove("codigo", { path: "/" });

    window.location = "/";
  };
  return (
    <button className="btn btn-secondary" onClick={cerrarSecion}>
      Cerrar Sesion{" "}
    </button>
  );
}
export default CerrarSesion;
