import React from "react";
import { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import CreaSeccion from "./CreaSeccion";
import EliminarSeccion from "./EliminaSeccion";
import ReactModal from "react-modal";
import InfoPreguntasxSeccion from "./InfoPreguntasxSeccion";
import EditarSeccion from "./EditaSeccion";
function InfoSecciones(props) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const handleOpenModalTest = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const searcher = (e) => {
    setSearch(e.target.value);
  };
  const results = !search
    ? users
    : users.filter((dato) =>
        dato.descripcion.toLowerCase().includes(search.toLocaleLowerCase())
      );
  useEffect(() => {
    showData();
  }, []);

  const URL =
    "https://autodiagnosticoback.azurewebsites.net/api/ObtenerSeccionPorNorma/" +
    props.id;
  const showData = async () => {
    const response = await fetch(URL);
    const data = await response.json();
    //console.log(data)
    setUsers(data);
  };

  return (
    <div>
      <Fragment>
        <button
          variant="contained"
          className="btn btn-primary "
          color="primary"
          onClick={handleOpenModalTest}
        >
          Secciones
        </button>
        <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Crear Institucion"
        >
          <div className="container">
            <div className="text-center">
              <div class="container-fluid">
                <div class="card shadow mb-4">
                  <div class="card-header py-3">
                    <h6 class="m-0 font-weight-bold text-primary">Secciones</h6>
                  </div>
                  <div class="card-body">
                    <CreaSeccion idNorma={props.id} />
                    <br></br>
                    <input
                      value={search}
                      onChange={searcher}
                      type="text"
                      placeholder="Buscar por sección "
                      className="form-control"
                    />
                    <div class="table-responsive">
                      <table
                        class="table table-bordered"
                        id="dataTable"
                        width="100%"
                        cellspacing="0"
                      >
                        <thead>
                          <tr>
                            <th>Descripción</th>
                            <th>Preguntas</th>
                            <th>Editar</th>
                            <th>Eliminar</th>
                          </tr>
                        </thead>
                        <tbody>
                          {results.map((item) => {
                            return (
                              <tr>
                                <td>{item.descripcion}</td>
                                <td>
                                  <InfoPreguntasxSeccion
                                    idSeccion={item.consecutivo}
                                    idNorma={item.normaId}
                                  />
                                </td>
                                <td>
                                  <EditarSeccion
                                    id={item.id}
                                    seccion={item.descripcion}
                                  />
                                </td>
                                <td>
                                  <EliminarSeccion id={item.id} />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <button
                        className="btn btn-secondary"
                        onClick={closeModal}
                      >
                        Volver
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
      </Fragment>
    </div>
  );
}

export default InfoSecciones;
