import React from "react";
import axios from "axios";
import * as IoIcons from "react-icons/io";
import Swal from "sweetalert2";
function EliminarPreguntas(props) {
  const Eliminar = (e) => {
    e.preventDefault(e);
    axios({
      auth: { username: "webapp", password: "762tsfsy9826kk87ytttsf" },
      method: "delete",
      url:
        "https://autodiagnosticoback.azurewebsites.net/api/EliminaPregunta/" +
        props.id,
      data: {},
    })
      .then((Response) => {
        console.log(Response.data);
        Swal.fire({
          title: Response.data,
          text: "Eliminado",
          icon: "success",
        });
        window.location.reload("/");
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "No se puedo eliminar",
          text: "Intentelo de nuevo",
          icon: "error",
        });
      });
  };
  return (
    <div>
      <button
        variant="contained"
        className="btn btn-danger"
        color="primary"
        onClick={Eliminar}
      >
        {" "}
        <IoIcons.IoIosCloseCircle />{" "}
      </button>
    </div>
  );
}

export default EliminarPreguntas;
