import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import ConsultarResultados from "./ConsultarResultados";
import MedidorNtc from "../Formato1/MedidorNtc";
import Detalles from "./Detalles";

function InfoResultados() {
  const cookie = new Cookies();
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const searcher = (e) => {
    setSearch(e.target.value);
  };
  const results = !search
    ? users
    : users.filter((dato) =>
        dato.norma.toLowerCase().includes(search.toLocaleLowerCase())
      );
  useEffect(() => {
    showData();
  }, []);

  const URL =
    "https://autodiagnosticoback.azurewebsites.net/api/EvaluacionesEnPausa/" +
    cookie.get("nitNumber") +
    "/Finalizado";
  const showData = async () => {
    const response = await fetch(URL);
    const data = await response.json();
    //console.log(data)
    setUsers(data);
  };
  return (
    <div>
      <div className="container">
        <div className="text-justify">
          <div class="container-fluid">
            <div class="card shadow mb-4">
              <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">
                  Evaluaciones Presentadas{" "}
                </h6>
              </div>

              <div class="card-body">
                <br></br>
                <div class="table-responsive">
                  <table
                    class="table table-bordered"
                    id="dataTable"
                    width="100%"
                    cellspacing="0"
                  >
                    <thead>
                      <tr>
                        <th>
                          <h6 class="m-0 font-weight-bold text-primary">
                            Cliente{" "}
                          </h6>
                        </th>
                        <th>
                          <h6 class="m-0 font-weight-bold text-primary">
                            Norma
                          </h6>
                        </th>
                        <th>
                          <h6 class="m-0 font-weight-bold text-primary">
                            {" "}
                            Ponderado Final{" "}
                          </h6>
                        </th>
                        <th>
                          <h6 class="m-0 font-weight-bold text-primary">
                            Fecha Creación{" "}
                          </h6>
                        </th>
                        <th>
                          <h6 class="m-0 font-weight-bold text-primary">
                            Consultar{" "}
                          </h6>
                        </th>
                        <th>
                          <h6 class="m-0 font-weight-bold text-primary">
                            Detalles{" "}
                          </h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {results.map((item) => {
                        const fecha = new Date(item.fechaCreacion);
                        return (
                          <tr>
                            <td>{item.cliente}</td>
                            <td>{item.norma}</td>
                            <td>
                              <div className="text-center">
                                {item.ponderado}0%
                              </div>
                            </td>
                            <td>
                              {fecha.toLocaleDateString(
                                "en-US",
                                item.fechaCreacion
                              )}
                            </td>
                            <td>
                              <ConsultarResultados
                                idNorma={item.id}
                                ponderado={item.ponderado}
                                nombreNorma={item.norma}
                              />
                            </td>
                            <td>
                              <Detalles
                                idEvaluacion={item.id}
                                nombreNorma={item.norma}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default InfoResultados;
