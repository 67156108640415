import React, { Fragment, useEffect, useState } from "react";
import ReactModal from "react-modal";
import axios from "axios";
//impoertacion componentes
import * as IoIcons from "react-icons/io";
import Swal from "sweetalert2";

function EditarSeccion(props) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const handleOpenModalTest = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const [postSeccion, setPostSeccion] = useState([]);
  const [postDescripcion, setPostDescripcion] = useState([]);
  const [postNormaId, setPostNormaId] = useState([]);
  const [postConsecutivo, setPostConsecutivo] = useState([]);

  const postData = (e) => {
    e.preventDefault(e);
    if (postSeccion != "") {
      axios({
        auth: { username: "webapp", password: "762tsfsy9826kk87ytttsf" },
        method: "put",
        url:
          "https://autodiagnosticoback.azurewebsites.net/api/ActualizaSeccion/" +
          props.id,
        data: {
          seccion: postSeccion,
        },
      })
        .then((Response) => {
          Swal.fire({
            title: Response.data,
            icon: "success",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              console.log(Response.data);

              window.location.reload("/");
            } else {
              Swal.fire("Your imaginary file is safe!");
            }
          });
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: "Error en los campos",
            text: "Intente de nuevo.",
            icon: "warning",
            timer: 3500,
          });
        });
    }
    if (postDescripcion != "") {
      axios({
        auth: { username: "webapp", password: "762tsfsy9826kk87ytttsf" },
        method: "put",
        url:
          "https://autodiagnosticoback.azurewebsites.net/api/ActualizaSeccion/" +
          props.id,
        data: {
          descripcion: postDescripcion,
        },
      })
        .then((Response) => {
          Swal.fire({
            title: Response.data,
            icon: "success",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              console.log(Response.data);

              window.location.reload("/");
            } else {
              Swal.fire("Your imaginary file is safe!");
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error en los campos",
            text: "Intente de nuevo.",
            icon: "warning",
            timer: 3500,
          });
        });
    }
    if (postNormaId != "") {
      axios({
        auth: { username: "webapp", password: "762tsfsy9826kk87ytttsf" },
        method: "put",
        url:
          "https://autodiagnosticoback.azurewebsites.net/api/ActualizaSeccion/" +
          props.id,
        data: {
          normaId: postNormaId,
        },
      })
        .then((Response) => {
          console.log(Response.data);
          Swal.fire({
            title: "Actualizado con exito!",
            text: "Añadido.",
            icon: "warning",
            timer: 3500,
          });
          window.location.reload("/");
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: "Error en los campos",
            text: "Intente de nuevo.",
            icon: "warning",
            timer: 3500,
          });
        });
    }
    if (postConsecutivo != "") {
      axios({
        auth: { username: "webapp", password: "762tsfsy9826kk87ytttsf" },
        method: "put",
        url:
          "https://autodiagnosticoback.azurewebsites.net/api/ActualizaSeccion/" +
          props.id,
        data: {
          consecutivo: postConsecutivo,
        },
      })
        .then((Response) => {
          Swal.fire({
            title: Response.data,
            icon: "success",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              console.log(Response.data);

              window.location.reload("/");
            } else {
              Swal.fire("Your imaginary file is safe!");
            }
          });
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: "Error en los campos",
            text: "Intente de nuevo.",
            icon: "warning",
            timer: 3500,
          });
        });
    }
  };
  return (
    <div className="test">
      <button
        variant="contained"
        className="btn btn-warning"
        onClick={handleOpenModalTest}
      >
        {" "}
        <IoIcons.IoIosSearch />
      </button>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Crear Institucion"
      >
        <div className="container">
          <div className="text-center">
            <div class="container-fluid">
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">
                    Actualiza Sección
                  </h6>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <form className="user">
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <textarea
                            type="text"
                            rows={4}
                            className="form-control form-control-user"
                            placeholder="Enunciado"
                            onChange={(e) => setPostDescripcion(e.target.value)}
                          >
                            {props.seccion}
                          </textarea>
                        </div>
                      </div>

                      <a>
                        <button
                          className="btn btn-primary btn-user btn-block"
                          onClick={postData}
                        >
                          Guardar
                        </button>
                      </a>
                      <hr />
                    </form>
                    <button className="btn btn-secondary" onClick={closeModal}>
                      Volver
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}
export default EditarSeccion;
