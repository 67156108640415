import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

import MedidorNtc from "../Formato1/MedidorNtc";
import { Link } from "react-router-dom";

function Continuar() {
  const cookie = new Cookies();
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const searcher = (e) => {
    setSearch(e.target.value);
  };
  const results = !search
    ? users
    : users.filter((dato) =>
        dato.norma.toLowerCase().includes(search.toLocaleLowerCase())
      );
  useEffect(() => {
    showData();
  }, []);

  const URL =
    "https://autodiagnosticoback.azurewebsites.net/api/EvaluacionesEnPausa/" +
    cookie.get("nitNumber") +
    "/en Progreso";
  const showData = async () => {
    const response = await fetch(URL);
    const data = await response.json();
    //console.log(data)
    setUsers(data);
  };
  const Reanudar = (e) => {
    e.preventDefault(e);
    window.location.href = "/#/NTC/";

    window.location.reload("/");
  };
  const stringNorma = results.map((item) => {
    return item.id;
  });
  const Norma = stringNorma.join(
    results.map((item) => {
      return item.id;
    })
  );
  return (
    <div>
      <div className="container">
        <div className="text-justify">
          <div class="container-fluid">
            <div class="card shadow mb-4">
              <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">
                  Evaluaciones en Curso
                </h6>
              </div>

              <div class="card-body">
                <br></br>
                <div class="table-responsive">
                  <input
                    value={search}
                    onChange={searcher}
                    type="text"
                    placeholder="Buscar por norma "
                    className="form-control"
                  />

                  <table
                    class="table table-bordered"
                    id="dataTable"
                    width="100%"
                    cellspacing="0"
                  >
                    <thead>
                      <tr>
                        <th>
                          <h6 class="m-0 font-weight-bold text-primary">
                            Cliente{" "}
                          </h6>
                        </th>
                        <th>
                          <h6 class="m-0 font-weight-bold text-primary">
                            Norma
                          </h6>
                        </th>
                        <th>
                          <h6 class="m-0 font-weight-bold text-primary">
                            {" "}
                            Ponderado Final{" "}
                          </h6>
                        </th>
                        <th>
                          <h6 class="m-0 font-weight-bold text-primary">
                            Fecha Creación{" "}
                          </h6>
                        </th>
                        <th>
                          <h6 class="m-0 font-weight-bold text-primary">
                            Continuar{" "}
                          </h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {results.map((item) => {
                        const fecha = new Date(item.fechaCreacion);
                        return (
                          <tr>
                            <td>{item.cliente}</td>
                            <td>{item.norma}</td>
                            <td>
                              <div className="text-center">
                                {item.ponderado}
                              </div>
                            </td>
                            <td>
                              {fecha.toLocaleDateString(
                                "en-US",
                                item.fechaCreacion
                              )}
                            </td>
                            <td>
                              {" "}
                              <Link to={"/NTC/" + item.id}> Continuar</Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Continuar;
