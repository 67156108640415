import React, { useEffect, useState } from "react";
import img from "../imagenes/logofot.png";
import axios from "axios";
import Cookies from "universal-cookie";
import { Alert } from "react-bootstrap";
import Swal from "sweetalert2";
import Footer from "./Footer";
function Home() {
  const cookie = new Cookies();
  const fecha = new Date();
  const [list, setList] = useState([]);
  useEffect(() => {
    axios({
      auth: { username: "webapp", password: "762tsfsy9826kk87ytttsf" },
      method: "get",
      url: "https://autodiagnosticoback.azurewebsites.net/api/ObtenerTodasNormas",
    })
      .then((Response) => {
        setList(Response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList]);
  const [postRegla, setPostRegla] = useState([]);
  const postData = (e) => {
    e.preventDefault(e);
    axios({
      method: "post",
      url: "https://autodiagnosticoback.azurewebsites.net/api/CreaEvaluacion",
      data: {
        cliente: cookie.get("nitNumber"),
        estado: "en progreso",
        idNormaCuestionario: postRegla,
      },
    })
      .then((Response) => {
        console.log(Response.data);
        if (Response.data == 2) {
          Swal.fire({
            title: "ya presento esta evaluación",
            icon: "warning",
          });
        } else {
          console.log(Response.data);
          Swal.fire({
            title: Response.data,
            icon: "success",
          }).then(function () {
            window.location.href = "/#/NTC/" + Response.data;

            window.location.reload("/");
          });

          console.log(Response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 offset-md-12">
          <center>
            <font color="#002E5D">
              <h2>Autoevaluación</h2>
            </font>
          </center>
          <div className="card my-10">
            <form className="card-body cardbody-color p-lg-5">
              <div className="text-justify">
                <font color="#002E5D">
                  <h2>¡Bienvenido!</h2>
                </font>{" "}
                Módulo de autoevaluaciones de cumplimiento de Normas Técnicas y
                Buenas Prácticas de Gestión de ICONTEC”. En este espacio virtual
                su organización podrá seleccionar normas técnicas y modelos de
                gestión de su interés, para realizar un diagnóstico de manera
                autónoma que le permita conocer el nivel de cumplimiento de
                dichas normas o modelos en forma global y por capítulos. La
                evaluación puede iniciarla y retomarla luego si no puede
                finalizarla en un solo momento, al finalizar podrá generar un
                reporte consolidado con su resultado el cual podrá descargar.
                Hemos desarrollado esta herramienta, con el fin de apoyar la
                competitividad y enfoque sostenible de las organizaciones, ya
                que estamos convenidos de su efectividad como mecanismo para
                promover:
              </div>
              <br></br>
              <div className="text-justify">
                <font color="#002E5D"> 1. </font>la Mejora continua y la buena
                práctica de la gestión de riesgos: Al realizar estas
                evaluaciones podrán detectar posibles desviaciones,
                oportunidades y riesgos que son entrada fundamental para definir
                acciones de mejora o estrategias competitivas para su sector.
              </div>
              <br></br>
              <div className="text-justify">
                <font color="#002E5D"> 2. </font> la Eficiencia: podrá
                identificar posibles ineficiencias en sus procesos, lo que
                permitirá optimizar recursos y mejorar la productividad.
              </div>
              <br></br>
              <div className="text-justify">
                <font color="#002E5D"> 3. </font> la transferencia de
                conocimiento: Las normas técnicas y modelos de gestión
                evolucionan para alinearse con los desarrollos tecnológicos
                emergentes. Mediante la autoevaluación, podrán mantenerse al día
                con tendencias de gestión en su sector.
              </div>
              <br></br>
              <div className="text-justify">
                <font color="#002E5D"> 4. </font> una Cultura de cumplimiento:
                La autoevaluación fomenta una cultura de cumplimiento. Lo
                invitamos a involucrar a todos los procesos de su organización
                en el proceso de autoevaluación, a fin de crear conciencia y
                responsabilidad compartida en el cumplimiento de normas,
                promoviendo una cultura de integridad.
              </div>
              <br></br>
              <div className="text-justify">
                Esperamos que este módulo sea del mayor valor para lograr sus
                propósitos. ¡Bienvenido a este viaje hacia la excelencia en el
                cumplimiento de normas y Buenas Prácticas de Gestión!
              </div>

              <br></br>
              <br></br>
              <i>
                <h6 className="text-center text-primary mt-5">
                  <strong>
                    Escoja Una Opción De La Norma Que Desea Presentar
                  </strong>
                </h6>
              </i>
              <br></br>
              <div className="form-group row">
                {cookie.get("afiliado") != "NO AFILIADO" ? (
                  <select
                    className="btn btn-primary btn-user btn-block"
                    onChange={(e) => setPostRegla(e.target.value)}
                  >
                    <option value="">Seleccione La Norma</option>

                    {list.map((item) => (
                      <option value={item.id}>{item.norma}</option>
                    ))}
                  </select>
                ) : (
                  <h1>
                    Si desea obtener este beneficio, por favor contactese con el
                    departamento comercial de Icontec
                  </h1>
                )}
              </div>
              <div className="form-group row">
                <div className="col-sm-12">
                  {postRegla != 0 ? (
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary btn-user btn-block"
                        onClick={postData}
                      >
                        Crear nuevo
                      </button>
                    </div>
                  ) : (
                    <h1></h1>
                  )}
                </div>
              </div>

              <hr />

              <hr />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
