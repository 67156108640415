import axios from "axios";
import React, { Component, useState } from "react";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import img from "../imagenes/logofot.png";
import fondo from "../imagenes/Degradado_ICONTEC-2019.png";
import sbadmin21 from "../../src/css/sb-admin-2.min.css";

function Login() {
  const [identificacion, setPostIdentificacion] = useState([]);
  const [codigo, setPostCodigo] = useState([]);
  const postData = (e) => {
    const cookie = new Cookies();
    Swal.fire({
      title: "Iniciando sesión...",
      text: "Espere un momento ..",
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    e.preventDefault(e);
    axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        type: "formData",
      },
      url:
        "https://autodiagnosticoback.azurewebsites.net/api/Auth?identificacion=" +
        identificacion +
        "&codigo=" +
        codigo,
    })
      .then((Response) => {
        var valida = Response.data;
        console.log(Response.data);
        if (valida == 8600123361) {
          {
            Swal.fire({
              title: "Bienvenido",
              text: "administrador",
              icon: "success",
            }).then(function () {
              cookie.set("nitNumber", Response.data, { path: "/" });
              window.location.href = "/#/InfoNormas";
              window.location.reload("/");
            });
          }
        } else {
          Swal.fire({
            title: "Verifique la información",
            text: "Debe realizar la solicitud",
            icon: "warning",
          }).then(function () {
            window.location.reload("/");
          });
        }
        if (valida != 8600123361) {
          var respuesta = Response.data;
          console.log(Response.data);

          cookie.set("cliente", respuesta.cliente, { path: "/" });
          cookie.set("nitNumber", respuesta.partY_NUMBER, { path: "/" });
          cookie.set(("nit", respuesta.nit, { path: "/" }));
          cookie.set("afiliado", respuesta.clasE_CLIENTE, { path: "/" });
          cookie.set("numeroCuenta", respuesta.numerO_CUENTA, { path: "/" });
          cookie.set(("codigo", respuesta.cusT_ACCOUNT_ID, { path: "/" }));

          {
            respuesta.clasE_CLIENTE == "AFILIADO" ? (
              <p>
                {Swal.fire({
                  title: "Bienvenido",
                  text: respuesta.CLIENTE,
                  icon: "success",
                }).then(function () {
                  window.location.href = "/#/Resultados";
                  window.location.reload("/");
                })}
              </p>
            ) : (
              <p>
                {Swal.fire({
                  title: Response.data,
                  text: "Debe realizar la solicitud",
                  icon: "warning",
                }).then(function () {
                  window.location.href = "https://tienda.icontec.org/";
                })}
              </p>
            );
          }
        }
      })

      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Datos Incorrectos",
          icon: "error",
        });
      });
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="card my-1">
              <form className="card-body cardbody-color p-lg-5">
                <div className="text-center">
                  <br></br> <br></br>
                  <img
                    src={img}
                    className="img-fluid profile-image-pic img-thumbnail rounded-circle my-3"
                    width="450px"
                    alt="profile"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    aria-describedby="emailHelp"
                    placeholder=" USUARIO"
                    onChange={(e) => setPostIdentificacion(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="CONTRASEÑA"
                    onChange={(e) => setPostCodigo(e.target.value)}
                  />
                </div>
                <hr />
                <center>
                  <a>
                    <button
                      className="btn btn-primary "
                      type="submit"
                      onClick={postData}
                    >
                      Iniciar Sesión
                    </button>
                  </a>
                </center>
                <hr />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
